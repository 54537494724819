<template>
  <div class="base-list-page">
    <el-form
      :inline="true"
      :model="searchForm"
      ref="dataForm"
      @keyup.enter.native="getDataList(1)"
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.freezerName"
            placeholder="智能取餐柜名字"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.deliveryName"
            placeholder="配送员名字"
            clearable
          />
        </el-form-item>
        <el-form-item class="typeInput">
          <el-select v-model="searchForm.status" clearable placeholder="状态">
            <el-option key="0" label="待开始" value="0" />
            <el-option key="1" label="配送中" value="1" />
            <el-option key="2" label="已完成" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)"> 查询 </el-button>
          <el-button
            v-if="isAuth('delivery:deliveryuser:adddelivery')"
            type="primary"
            @click="$dialog('addOrUpdate')"
          >
            新增
          </el-button>
          <el-button
            v-if="isAuth('delivery:deliveryuser:delete')"
            type="danger"
            :disabled="dataListSelections.length <= 0"
            @click="deleteHandle()"
          >
            批量删除
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      style="width: 100%"
      ref="dataList"
      @select-all="handleSelectAll"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="deliveryName"
        header-align="center"
        align="center"
        label="配送员"
      />
      <el-table-column
        prop="mobile"
        header-align="center"
        align="center"
        label="手机号码"
      />
      <el-table-column
        prop="freezerName"
        header-align="center"
        align="center"
        label="智能取餐柜名字"
      />
      <el-table-column
        prop="upperShelves"
        header-align="center"
        align="center"
        label="上货柜"
      />
      <el-table-column
        prop="middleShelves"
        header-align="center"
        align="center"
        label="中货柜"
      />
      <el-table-column
        prop="lowerShelves"
        header-align="center"
        align="center"
        label="下货柜"
      />
      <el-table-column
        prop="status"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="danger">
            待开始
          </el-tag>
          <el-tag
            v-else-if="scope.row.status === 1"
            size="small"
            type="success"
          >
            配送中
          </el-tag>
          <el-tag
            v-else-if="scope.row.status === 2"
            size="small"
            type="success"
          >
            已完成
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间"
      />
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('delivery:deliverytask:update')"
            type="text"
            size="small"
            @click="$dialog('addOrUpdate', scope.row.id)"
          >
            修改
          </el-button>
          <el-button
            v-if="isAuth('delivery:deliverytask:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id, scope.row.name)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @success="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './delivery-task-add-or-update';
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      searchForm: {
        freezerName: '',
        deliveryName: '',
        status: '',
      },
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      this.$api({
        url: '/delivery/deliverytask/list',
        params: {
          page: page ? page : this.pageIndex,
          limit: this.pageSize,
          deliveryName: this.searchForm.deliveryName,
          freezerName: this.searchForm.freezerName,
          status: this.searchForm.status,
        },
        after: (data) => {
          if (data && data.code === 0) {
            this.dataList = data.page.records;
            this.totalPage = data.page.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        },
      });
    },
    resetHandle() {
      let ids = this.dataListSelections.map((item) => {
        return item.id;
      });
      this.$confirm(`重置后的密码为 123456 ，是否确定？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/delivery/deliverytask/save',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
    // 删除
    deleteHandle(id, name) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      let names = name
        ? [name]
        : this.dataListSelections.map((item) => {
            return item.name;
          });
      this.$confirm(
        `确定对${names.join(',')}进行${id ? '删除' : '批量删除'}操作?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
        .then(() => {
          this.$http({
            url: '/delivery/deliverytask/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
